import * as fromAuth from '../../../auth/store/reducers/auth';
import * as fromSetting from '../../../setting/store/reducers/setting.reducer';
import { ActionReducerMap } from '@ngrx/store';
import * as fromLayout from './layout';
import * as fromMetadata from './metadata.reducer';
import * as fromSportBook from 'src/app/jackpot/store/reducers/sportsbook';
import * as fromLottoSportBook from 'src/app/lotto/store/reducers/lotto.sportsbook.reducer';
import * as fromGames from '../../../casino/store/reducers/games.reducer';
import * as fromNotificationTemplates from '../../../notifications/store/reducers/notification-templates.reducer';
import * as fromExpenses from '../../../expenses/store/reducers/expenses.reducer';
import * as fromExpensesRequests from '../../../expenses/modules/expenses-requests/store/reducers/expenses-requests.reducer';
import * as fromExpensesReport from '../../../expenses/modules/expenses-report/store/reducers/expenses-report.reducer';
import * as fromBranchSummary from '../../../branch-summary-reports/store/reducers/branch-summary.reducer';
import * as fromOnlineVoucher from '../../../voucher/online-vouchers.reducer';
import * as fromVoucherList from '../../../voucher/online-batches.reducers';
import * as fromActiveCampaigns from '../../../bonus/store/reducers/activeCampaigns';
import * as fromBrandSettings from '../../../brand-settings/store/reducers/brand-settings.reducers';
import * as fromCMSLanguages from '../../../languages-management/store/reducers/cms.languages.reducer';
import * as fromCMSBanners from '../../../banner-management/store/reducers/cms.banners.reducer';
import * as fromCMSLabels from '../../../label-management/store/reducers/cms.labels.reducer';
import * as fromCMSHtmls from '../../../htmls-management/store/reducers/cms.htmls.reducer';
import * as fromCMSSeo from '../../../seo-management/store/reducers/cms.seo-management.reducers';
import * as fromEventsMetadata from '../../../events/store/reducers/events.metadata.reducer';
import * as fromEvents from '../../../events/store/reducers/events.reducer';
import * as fromOnline from 'abs@core/store/reducers/online.reducer';
import * as fromSettingsSportsBook from '../../../cashout/store/reducers/settings.sportsbook.reducer';
import * as fromBetslips from 'src/app/betslips/store/reducers/betslips.reducer';
import * as fromOrder from 'src/app/betslips/store/reducers/order.reducer';
import * as fromOnlineCustomersList from '../../../customer-list/store/reducers/online-customers-list.reducer';
import * as fromRolesAndPermissions from '../../../roles-and-permissions/store/reducers/roles-and-permissions.reducer';
import * as fromExposure from '../../../risk/exposure/store/reducers/exposure.reducer';
import * as fromCMSMenuManagement from '../../../menu-management/store/reducers/menu-management.reducer';
import * as fromCMS from '../../../cms/store/reducers/cms.reducer';
import * as fromBOFilter from '../../../shared/filters/bo-filter/store/reducers/bo-filter.reducers';
import * as fromBatchData from '../../../voucher/store/reducers/batch.reducer';
import * as fromVoucherData from '../../../voucher/store/reducers/create-batch.reducer';
import * as userAccessData from '../../../customer-list/modules/user-access/store/reducers/user-access.reducer';
import * as fromReportData from '../../../voucher/online-report.reducer';
import * as fromBetTypesAdapter from '../../../sport-betting/modules/bet-types/store/adapters';
import * as fromBetTypeOddsAdapter from '../../../sport-betting/modules/bet-types/store/adapters';
import * as fromBetTypeMarginsAdapter from '../../../sport-betting/modules/bet-type-margins/store/adapters';
import * as fromBetTypesReduser from '../../../sport-betting/modules/bet-types/store/reducers';
import * as fromEventAdapter from '../../../sport-betting/modules/events-lobby/store/adapters';
import * as fromEventReduser from '../../../sport-betting/modules/events-lobby/store/reducers';
import * as fromEventDetailAdapter from '../../../sport-betting/modules/events/store/adapters';
import * as fromEventDetailReduser from '../../../sport-betting/modules/events/store/reducers';
import * as fromBetTypeOddsReducer from '../../../sport-betting/modules/bet-types/store/reducers';
import * as fromBetTypeMarginsReducers from '../../../sport-betting/modules/bet-type-margins/store/reducers';
import * as fromInstantWin from '../../../instant-win/store/reducers/instant-win.reducer';
import { SportCounriesState, SportLeagueState, SportTeamsState, SportsTypesState } from 'src/app/sport-betting/modules/sport-types/store/adapters';
import { SportTypesReducer, SportCounriesReducer, SportLeaguesReducer, SportTeamsReducer } from 'src/app/sport-betting/modules/sport-types/store/reducers';

export interface AppState {
  soccerMatchReducer: fromEventDetailAdapter.SoccerMatchState;
  eventDetailReducer: fromEventDetailAdapter.EventsState;
  eventReducer: fromEventAdapter.EventsState;
  boFilterReducer: fromBOFilter.State;
  exposureReducer: fromExposure.State;
  betTypesReducer: fromBetTypesAdapter.BetTypesState;
  betTypeOddsReducer: fromBetTypeOddsAdapter.BetTypeOddsState;
  betTypeGroupsReducer: fromBetTypesAdapter.BetTypeGroupsState;
  betTypeMarginsReducer: fromBetTypeMarginsAdapter.BetTypeMarginsState;
  betTypeCountryMarginsReducer: fromBetTypeMarginsAdapter.BetTypeCountryMarginsState;
  betTypeLeagueMarginsReducer: fromBetTypeMarginsAdapter.BetTypeLeagueMarginsState;
  sportsTypesReducer: SportsTypesState;
  sportCounriesReducer: SportCounriesState;
  sportLeaguesReducer: SportLeagueState;
  sportTeamsReducer: SportTeamsState;
  eventsMetadataReducer: fromEventsMetadata.State;
  eventsReducer: fromEvents.State;
  onlineReducer: fromOnline.State;
  authReducer: fromAuth.State;
  cmsLanguagesReducer: fromCMSLanguages.State;
  cmsBannersReducer: fromCMSBanners.State;
  cmsLabelsReducer: fromCMSLabels.State;
  cmsHtmlsReducer: fromCMSHtmls.State;
  cmsSEOReducer: fromCMSSeo.State;
  cmsReducer: fromCMS.State;
  cmsMenuManagementReducer: fromCMSMenuManagement.State;
  layoutReducer: fromLayout.State;
  metadataReducer: fromMetadata.State;
  settingReducer: fromSetting.State;
  sportsBookReducer: fromSportBook.State;
  lottoSportsBookReducer: fromLottoSportBook.State;
  settingsSportsBookReducer: fromSettingsSportsBook.State;
  gamesReducer: fromGames.State;
  notificationTemplateReducer: fromNotificationTemplates.State;
  expensesReducer: fromExpenses.State;
  expensesRequestsReducer: fromExpensesRequests.State;
  expensesReportReducer: fromExpensesReport.State;
  branchSummaryReducer: fromBranchSummary.State;
  activeCampaignsReducer: fromActiveCampaigns.State;
  brandSettingsReducer: fromBrandSettings.State;
  betslipsReducer: fromBetslips.State;
  orderReducer: fromOrder.State;
  onlineCustomersListReducer: fromOnlineCustomersList.State;
  onlineVoucherListReducer: fromOnlineVoucher.State;
  onlineListReducer: fromVoucherList.State;
  rolesAndPermissionsReducer: fromRolesAndPermissions.State;
  batchDataReducer: fromBatchData.State;
  voucherDataReducer: fromVoucherData.State;
  userAccessReducer: userAccessData.State;
  reportDataReducer: fromReportData.State;
  fastGamesManagementReducer: fromInstantWin.State;
}

export const reducers: ActionReducerMap<AppState> = {
  soccerMatchReducer: fromEventDetailReduser.SoccerMatchReducer,
  eventDetailReducer: fromEventDetailReduser.EventDetailReducer,
  eventReducer: fromEventReduser.EventReducer,
  betTypeGroupsReducer: fromBetTypesReduser.BetTypeGroupsReducer,
  betTypesReducer: fromBetTypesReduser.BetTypesReducer,
  betTypeOddsReducer: fromBetTypeOddsReducer.BetTypeOddsReducer,
  betTypeMarginsReducer: fromBetTypeMarginsReducers.BetTypeMarginsReducer,
  betTypeCountryMarginsReducer: fromBetTypeMarginsReducers.BetTypeCountryMarginsReducer,
  betTypeLeagueMarginsReducer: fromBetTypeMarginsReducers.BetTypeLeagueMarginsReducer,
  sportsTypesReducer: SportTypesReducer,
  sportCounriesReducer: SportCounriesReducer,
  sportLeaguesReducer: SportLeaguesReducer,
  sportTeamsReducer: SportTeamsReducer,
  boFilterReducer: fromBOFilter.BOFilterReducer,
  exposureReducer: fromExposure.ExposureReducer,
  onlineReducer: fromOnline.OnlineReducer,
  eventsMetadataReducer: fromEventsMetadata.EventsMetadataReducer,
  eventsReducer: fromEvents.EventsReducer,
  authReducer: fromAuth.AuthReducer,
  cmsLanguagesReducer: fromCMSLanguages.CmsLanguagesReducer,
  cmsBannersReducer: fromCMSBanners.CmsBannersReducer,
  cmsLabelsReducer: fromCMSLabels.CmsLabelsReducer,
  cmsHtmlsReducer: fromCMSHtmls.CmsHtmlsReducer,
  cmsSEOReducer: fromCMSSeo.CmsSEOReducer,
  cmsMenuManagementReducer: fromCMSMenuManagement.CmsMenuManagementReducer,
  cmsReducer: fromCMS.CmsReducer,
  layoutReducer: fromLayout.LayoutReducer,
  metadataReducer: fromMetadata.MetaDataReducer,
  settingReducer: fromSetting.SettingReducer,
  sportsBookReducer: fromSportBook.SportsbookReducer,
  settingsSportsBookReducer: fromSettingsSportsBook.SettingsSportsBookReducer,
  lottoSportsBookReducer: fromLottoSportBook.LottoSportsBookReducer,
  gamesReducer: fromGames.GamesReducer,
  notificationTemplateReducer: fromNotificationTemplates.NotificationTemplateReducer,
  expensesReducer: fromExpenses.ExpensesReducer,
  expensesRequestsReducer: fromExpensesRequests.ExpensesRequestsReducer,
  expensesReportReducer: fromExpensesReport.ExpensesReportReducer,
  branchSummaryReducer: fromBranchSummary.BranchSummaryReducer,
  activeCampaignsReducer: fromActiveCampaigns.ActiveCampaignsReducer,
  brandSettingsReducer: fromBrandSettings.BrandSettingsReducer,
  betslipsReducer: fromBetslips.BetslipsReducer,
  orderReducer: fromOrder.OrderReducer,
  onlineCustomersListReducer: fromOnlineCustomersList.OnlineCustomersReducer,
  onlineVoucherListReducer: fromOnlineVoucher.OnlineVoucherReducer,
  onlineListReducer: fromVoucherList.OnlineListReducer,
  rolesAndPermissionsReducer: fromRolesAndPermissions.RolesAndPermissionsReducer,
  batchDataReducer: fromBatchData.BatchReducer,
  voucherDataReducer: fromVoucherData.voucherReducer,
  userAccessReducer: userAccessData.UserAccessReducer,
  reportDataReducer: fromReportData.OnlineReportReducer,
  fastGamesManagementReducer: fromInstantWin.fastGamesManagementReducer
};
