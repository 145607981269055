import { Action } from '@ngrx/store';

export const GET_LABELS_FAILURE = '[CMS-Banner] Get Labels Failure';
export const EDIT_NEW_LABEL = '[CMS-Label] Edit new label';
export const EDIT_EXISTING_LABEL = '[CMS-Label] Edit existing label';
export const LABELS_ACTION_REQUEST = '[CMS-Label] Label action request';
export const LABELS_ACTION_FORM_REQUEST = '[CMS-Label] Label action form request';

export const SAVE_LABEL_FAILURE = '[CMS-Label] Save Label Failure';

export const GET_LABEL_DATA = '[CMS-Label] Get Label Data';
export const SET_LABEL_DATA = '[CMS-Label] Set Label Data';
export const UPDATE_LABEL_DATA_SUCCESS = '[CMS-Label] Update Label Data success';
export const CLONE_LABEL_DATA_SUCCESS = '[CMS-Label] Clone Label Data success';
export const DELETE_LABEL_SUCCESS = '[CMS-Label] Delete Label success';
export const SET_SUPPORTED_LANGUAGES = '[CMS-Label] Set Supported Languages';
export const GET_LABEL_PLACEMENTS = '[CMS-Label] Get label placements';
export const SET_LABEL_PLACEMENTS = '[CMS-Label] Set label placements';
export const EDIT_LABEL_PLACEMENT = '[CMS-Label] Edit label placement';
export const EDIT_LABEL_PLACEMENT_SUCCESS = '[CMS-Banner] Edit Banner placement success';
export const GET_PLACEMENT_FAILURE = '[CMS-Banner] Get placement failure';
export const CLOSE_EDITED_LABEL = '[CMS-Banner] Remove edited Label';

export class CloneLabelSuccess implements Action {
  readonly type = CLONE_LABEL_DATA_SUCCESS;

  constructor(public payload: any = null) {}
}
export class DeleteLabelSuccess implements Action {
  readonly type = DELETE_LABEL_SUCCESS;

  constructor(public payload: any = null) {}
}
export class UpdateLabelSuccess implements Action {
  readonly type = UPDATE_LABEL_DATA_SUCCESS;

  constructor(public payload: any = null) {}
}

export class GetLabelData implements Action {
  readonly type = GET_LABEL_DATA;

  constructor(public payload: any = null) {}
}
export class SetLabelData implements Action {
  readonly type = SET_LABEL_DATA;
  constructor(public payload: any = null) {}
}
export class EditNewLabel implements Action {
  readonly type = EDIT_NEW_LABEL;
  constructor(public payload: any = null) {}
}
export class EditExistingLabel implements Action {
  readonly type = EDIT_EXISTING_LABEL;
  constructor(public payload: any = null) {}
}
export class LabelsActionRequest implements Action {
  readonly type = LABELS_ACTION_REQUEST;
  constructor(public payload: any = null) {}
}
export class LabelsActionFormRequest implements Action {
  readonly type = LABELS_ACTION_FORM_REQUEST;
  constructor(public payload: any = null) {}
}
export class GetLabelsFailure implements Action {
  readonly type = GET_LABELS_FAILURE;


}
export class GetLabelPlacements implements Action {
  readonly type = GET_LABEL_PLACEMENTS;

  constructor(public payload: any = null) {}
}
export class SetLabelPlacements implements Action {
  readonly type = SET_LABEL_PLACEMENTS;

  constructor(public payload: any = null) {}
}
export class EditLabelPlacement implements Action {
  readonly type = EDIT_LABEL_PLACEMENT;

  constructor(public payload: any = null) {}
}
export class EditLabelPlacementSuccess implements Action {
  readonly type = EDIT_LABEL_PLACEMENT_SUCCESS;

  constructor(public payload: any = null) {}
}
export class GetPlacementFailure implements Action {
  readonly type = GET_PLACEMENT_FAILURE;

  constructor(public payload: any = null) {}
}

export class SaveLabelFailure implements Action {
  readonly type = SAVE_LABEL_FAILURE;


}

export class SetSupportedLanguages implements Action {
  readonly type = SET_SUPPORTED_LANGUAGES;

  constructor(public payload: any = null) {}
}

export class CloseEditedLabel implements Action {
  readonly type = CLOSE_EDITED_LABEL;

  constructor(public payload: any = null) {}
}

export type Actions =
  | UpdateLabelSuccess
  | DeleteLabelSuccess
  | CloneLabelSuccess
  | LabelsActionRequest
  | LabelsActionFormRequest
  | GetLabelsFailure
  | SaveLabelFailure
  | GetLabelData
  | SetLabelData
  | EditNewLabel
  | EditExistingLabel
  | SetSupportedLanguages
  | GetLabelPlacements
  | SetLabelPlacements
  | GetPlacementFailure
  | EditLabelPlacement
  | EditLabelPlacementSuccess
  | CloseEditedLabel;
